import { Box, Paper, Grid, Typography, Button, Stack } from '@mui/material';
import { FormMode } from '../utils/extensions/constantEx';

export default function PaymentFormHeader(props) {
    const { formMode } = props;

    return (

        <Paper>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item xs={2}>
                    <Typography variant="h5" sx={{ p: 2 }}>
                        {
                            formMode == FormMode.EditMode ? 'Edit Service Payment' :
                                formMode == FormMode.NewMode ? 'New Service Payment' : 'View Service Payment'
                        }
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Box sx={{ pr: 1, textAlign: 'right', paddingTop: '14px' }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained">Save</Button>
                            <Button variant="outlined">{formMode == FormMode.ViewMode ? 'Close' : 'Cancel'}</Button>
                        </Stack>

                    </Box>
                </Grid>
            </Grid>
        </Paper>

    );
}