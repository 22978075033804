import * as React from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

export default function PaymentMenuPopup() {
    return (
        <PopupState variant="popover" popupId="service-payment-search-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close} disableRipple>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={popupState.close} disableRipple>
                            Delete
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
} 