export const AlertInitalState = {
    isOpenSuccessAlert: false,
    isOpenErrorAlert: false,
    message: ""
};

export const AlertReducer = (state = AlertInitalState, action) => {
    switch (action.type) {
        case "OPEN_SUCCESS_ALERT":
            return {
                ...state,
                isOpenErrorAlert: false,
                isOpenSuccessAlert: true,
                message: action.payload.message ? action.payload.message.trim() : ""
            }

        case "OPEN_ERROR_ALERT":
            return {
                ...state,
                isOpenErrorAlert: true,
                isOpenSuccessAlert: false,
                message: action.payload.message ? action.payload.message.trim() : ""
            }

        case "CLOSE_ALERT":
            return {
                ...state,
                isOpenErrorAlert: false,
                isOpenSuccessAlert: false,
                message: ""
            }

        default:
            return { ...state };

    }
}
