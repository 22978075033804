import React from 'react';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

export default function ServiceMenuPopup() {
    const navigate = useNavigate();

    const handleNewPaymentClick = (popupState) => {
        popupState.close();
        navigate("/service-payment-form", { replace: false });
    }

    const handleViewPaymentsClick = (popupState) => {
        popupState.close();
        navigate("/service-payments", { replace: false });
    }

    const handleEditClick = (popupState) => {
        popupState.close();
        navigate("/service-form", { replace: false });
    }

    const handleDeleteClick = (popupState) => {
        popupState.close();
    }


    return (
        <PopupState variant="popover" popupId="service-search-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => handleNewPaymentClick(popupState)} disableRipple>
                            New Service Payment
                        </MenuItem>
                        <MenuItem onClick={() => handleViewPaymentsClick(popupState)} disableRipple>
                            View Service Payments
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem onClick={() => handleEditClick(popupState)} disableRipple>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteClick(popupState)} disableRipple>
                            Delete
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
} 