import React, { useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppToolBar from './appToolBar';
import Copyright from '../shared/components/copyright';
import PageRoute from './pageRoute';
import { HomeContext } from '../utils/contexts/homeContext';
import AlertComponent from '../shared/components/alert';
import { DocumentAPIUrl } from '../utils/extensions/functionEx';
import { ACCOUNT_ICON_DOC_ID } from '../startups/apiConfig';

function ViewPage() {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <PageRoute />
      <Outlet />

      <AlertComponent />
    </Box>
  );
}

export default function Dashboard() {

  const { homeState, SetAccountPersonIcon } = useContext(HomeContext);

  useEffect(() => {

    async function getAccountPersonIcon() {
      try {
        const response = await fetch(DocumentAPIUrl(homeState.loggedUser.companyCode, ACCOUNT_ICON_DOC_ID))
        if (response.status === 200) {
          const docTempJson = await response.json();
          if (docTempJson && docTempJson.uploaded_file) {
            SetAccountPersonIcon(docTempJson.uploaded_file);
          }
        }
      }
      catch (error) {

      }
    }
    getAccountPersonIcon();
  }, []);


  return (
    <Box>
      <AppToolBar userFullName={homeState.loggedUser.fullName} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={12}>
            <ViewPage />

          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Copyright sx={{ pt: 0 }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}