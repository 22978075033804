import { useReducer } from 'react';
import { AlertInitalState, AlertReducer } from '../hooks/useAlert';

export const HomeInitalState = {
    loggedUser: {
        companyId: 1,
        companyCode: 'a1',
        fullName: "Shajan",
        userId: 1
    },
    accountPersonIcon: null,
    nurseSearchText: ''
};

export const HomeReducer = (state = HomeInitalState, action) => {
    let tempLoggedUser = { ...state.loggedUser };

    switch (action.type) {
        case "SET_LOGGED_USER":
            tempLoggedUser.companyId = action.payload.companyId;
            tempLoggedUser.companyCode = action.payload.companyCode;
            tempLoggedUser.userId = action.payload.userId;
            tempLoggedUser.fullName = action.payload.fullName;

            return {
                ...state,
                loggedUser: { ...tempLoggedUser }
            }

        case "CLEAR_LOGGED_USER":
            tempLoggedUser.companyId = 0;
            tempLoggedUser.companyCode = "";
            tempLoggedUser.userId = 0;
            tempLoggedUser.fullName = "";

            return {
                ...state,
                loggedUser: { ...tempLoggedUser }
            }

        case "SET_NURSE_SEARCH_TEXT":
            return {
                ...state,
                nurseSearchText: action.payload.searchText
            }

        case "ACCOUNT_PERSON_ICON":
            return {
                ...state,
                accountPersonIcon: action.payload.accountIcon
            }

        default:
            return { ...state };

    }
}


export const useHome = () => {
    const [alertState, dispatchAlert] = useReducer(AlertReducer, AlertInitalState);
    const [homeState, dispatchHome] = useReducer(HomeReducer, HomeInitalState);

    const OpenSuccessAlert = (message) => {
        dispatchAlert({
            type: "OPEN_SUCCESS_ALERT",
            payload: {
                message: message
            }
        })
    }

    const OpenErrorAlert = (error) => {

        let errorMsg = '';

        if (typeof error === "string") {
            errorMsg = error;
        } else if (typeof error === "object") {
            errorMsg = error.message ? error.message : error.toString();
        }

        dispatchAlert({
            type: "OPEN_ERROR_ALERT",
            payload: {
                message: errorMsg
            }
        })
    }

    const CloseAllAlerts = () => {
        dispatchAlert({
            type: "CLOSE_ALERT",
            payload: null
        })
    }

    const SetLoggedUser = (companyId, companyCode, userId, userFullName) => {
        dispatchHome({
            type: "SET_LOGGED_USER",
            payload: {
                companyId: companyId,
                companyCode: companyCode,
                userId: userId,
                fullName: userFullName
            }
        })
    }

    const ClearLoggedUser = () => {
        dispatchHome({
            type: "CLEAR_LOGGED_USER",
            payload: {
                userId: 0,
                fullName: ''
            }
        })
    }

    const ShowAPIError = async (response) => {
        const tempJson = await response.json();
        if (tempJson && tempJson.error) {
            OpenErrorAlert(tempJson.error)
        }
    }

    const SetNurseSearchText = (searchText) => {
        dispatchHome({
            type: "SET_NURSE_SEARCH_TEXT",
            payload: {
                searchText: searchText
            }
        })
    }

    const SetAccountPersonIcon = (accountIcon) => {
        dispatchHome({
            type: "ACCOUNT_PERSON_ICON",
            payload: {
                accountIcon: accountIcon
            }
        })
    }

    return {
        alertState,
        homeState,
        OpenSuccessAlert,
        OpenErrorAlert,
        CloseAllAlerts,
        ShowAPIError,
        SetLoggedUser,
        ClearLoggedUser,
        SetNurseSearchText,
        SetAccountPersonIcon
    }
}