import React, { useState } from 'react';
import { Box, Stack, Paper, Grid, Typography, Table, TableBody, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { AccordionSummary, AccordionDetails, Accordion } from '../shared/components/accordion';
import StyledTableRow from '../shared/components/styledTableRow';
import StyledTableCell from '../shared/components/styledTableCell';
import PatientFamilyForm from './patientFamilyForm';

const PatientFamilyView = (props) => {
    const { expanded, patientFamilies, handleAccordionChange } = props;

    const [isOpenFamilyForm, setIsOpenFamilyForm] = useState(false);
    const [nextFamilyId, setNextFamilyId] = useState(-1);

    const handleAddClick = () => {
        setIsOpenFamilyForm(true);
    }

    const handleSaveClick = (nurseFamilyData) => {
        patientFamilies.push({ ...nurseFamilyData, id: nextFamilyId });
        setNextFamilyId(nextFamilyId + 1);
        setIsOpenFamilyForm(false);
    }

    const handleCancelClick = () => {
        setIsOpenFamilyForm(false);
    }

    return (

        <Accordion expanded={expanded === 'panelFamily'} onChange={handleAccordionChange('panelFamily')}>
            <AccordionSummary id="panel-pattient-families">
                <Typography>Patient Families and Contacts</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box sx={{ pr: 1, float: 'right' }}>
                                <Stack direction="row" spacing={2}>
                                    {
                                        isOpenFamilyForm &&
                                        <PatientFamilyForm isOpen={isOpenFamilyForm}
                                            handleSaveClick={handleSaveClick}
                                            handleCancelClick={handleCancelClick} />
                                    }
                                    <Button variant="contained" onClick={handleAddClick}>Add</Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ maxHeight: '77vh' }}>
                                <Table stickyHeader sx={{ minWidth: 700 }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Relation</StyledTableCell>
                                            <StyledTableCell>Phone No</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patientFamilies.map((row) => (
                                            <StyledTableRow key={row.name}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell>{row.relation}</StyledTableCell>
                                                <StyledTableCell>{row.phoneNo}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Stack>
            </AccordionDetails>
        </Accordion>

    );
}
export default PatientFamilyView;