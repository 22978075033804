import React, { createContext } from 'react';
import { useHome } from '../hooks/useHome'

export const HomeContext = createContext(null);

export const HomeContextContextProvider = ({ children }) => {

    const homeContext = useHome();

    return (
        <HomeContext.Provider value={homeContext}>
            {children}
        </HomeContext.Provider>
    )
}
