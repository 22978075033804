import { Box, Link, Paper, Typography } from '@mui/material';

export default function Copyright(props) {
    return (
        <Box sx={{ position: "fixed", bottom: '0', width: '100%', zIndex: '100' }}>
            <Paper elevation={3}>
                <Box sx={{ padding: '10px 5px', backgroundColor: 'rgb(65 66 68 / 14%)' }}>
                    <Typography variant="body2" sx={{ color: 'whitesmoke' }} {...props}>
                        {'Copyright © '}
                        <Link color="inherit" href="https://mui.com/">
                            Your Website
                        </Link>{' '}
                        {new Date().getFullYear()}
                    </Typography></Box>
            </Paper>
        </Box>
    );
}

// #f2f2f2 rgb(65 66 68 / 14%)