import React, { useContext } from "react"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { HomeContext } from '../../utils/contexts/homeContext';

const AlertComponent = (props) => {
    const { alertState, CloseAllAlerts } = useContext(HomeContext);

    const { message, isOpenSuccessAlert, isOpenErrorAlert } = alertState;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        CloseAllAlerts();
    };

    const isOpenSnackbar = (message && message.trim().length > 0) && (isOpenSuccessAlert || isOpenErrorAlert) ? true : false;
    
    return (
        <React.Fragment>
            {
                isOpenErrorAlert &&
                <Snackbar open={isOpenSnackbar} autoHideDuration={5000} onClose={handleClose} disableWindowBlurListener={true}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {message}
                    </Alert>
                </Snackbar>

            }
            {
                isOpenSuccessAlert &&
                <Snackbar open={isOpenSnackbar}
                    autoHideDuration={5000} onClose={handleClose} disableWindowBlurListener={true}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert variant="filled" severity="success" onClose={handleClose} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            }
        </React.Fragment>
    );
}

export default AlertComponent;

