import React from 'react';
import Box from '@mui/material/Box';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from './dashboard';
import { HomeContextContextProvider } from '../utils/contexts/homeContext';

const HomeContainer = (props) => {

    return (
        <Box>
            <BrowserRouter>
                <Dashboard />
            </BrowserRouter>
        </Box>
    );
}

export default function Index() {

    return (
        <HomeContextContextProvider>
            <HomeContainer />
        </HomeContextContextProvider>
    );
}