import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Stack, TextField } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NurseFamilyForm = (props) => {
    const { isOpen, selectedFamily, handleSaveClick, handleCancelClick } = props;

    const [familyName, setFamilyName] = useState('');
    const [relation, setRelation] = useState('');
    const [mobileNo, setMobileNo] = useState('');

    useEffect(() => {
        if (selectedFamily) {
            setFamilyName(selectedFamily.name ?? '');
            setRelation(selectedFamily.relation ?? '');
            setMobileNo(selectedFamily.phoneNo ?? '');
        }
    }, [selectedFamily]);

    const handleFamilySaveClick = () => {
        const nurseFamily = {
            id: selectedFamily && selectedFamily.id && selectedFamily.id !== 0 ? selectedFamily.id : 0,
            name: familyName.trim(),
            relation: relation.trim(),
            phoneNo: mobileNo.trim()
        };
        handleSaveClick(nurseFamily);
    }

    return (
        <React.Fragment>
            <Dialog maxWidth={"sm"} fullWidth
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancelClick}
            >
                <DialogTitle>Add Nurse Family</DialogTitle>
                <DialogContent>
                    <Box sx={{ paddingTop: '14px' }}>
                        <Stack spacing={2}>
                            <TextField size="small"
                                label="Family Name"
                                value={familyName}
                                onChange={(event) => {
                                    setFamilyName(event.target.value);
                                }}
                            />
                            <TextField size="small"
                                label="Relation"
                                value={relation}
                                onChange={(event) => {
                                    setRelation(event.target.value);
                                }}
                            />
                            <TextField size="small"
                                label="Mobile No"
                                value={mobileNo}
                                onChange={(event) => {
                                    setMobileNo(event.target.value);
                                }}
                            />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ paddingRight: "24px" }}>
                    <Button variant="contained" size="small" onClick={handleFamilySaveClick}>Save</Button>
                    <Button variant="outlined" size="small" onClick={handleCancelClick}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default NurseFamilyForm;
