import { Box, Paper, Grid, Typography, Button, Stack } from '@mui/material';
import { FormMode } from '../utils/extensions/constantEx';

export default function ServiceFormHeader(props) {
    const { formMode, handleEditClick, handleSaveClick, handleCancelClick } = props;

    return (

        <Paper sx={{ zIndex: '900' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item xs={2}>
                    <Typography variant="h5" sx={{ p: 2 }}>
                        {
                            formMode === FormMode.EditMode ? 'Edit Service' :
                                formMode === FormMode.NewMode ? 'New Service' : 'View Service'
                        }
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Box sx={{ pr: 1, float: 'right', paddingTop: '14px' }}>
                        <Stack direction="row" spacing={2}>
                            {
                                formMode === FormMode.ViewMode &&
                                <Button variant="contained" onClick={handleEditClick}>Edit</Button>
                            }
                            {
                                formMode !== FormMode.ViewMode &&
                                <Button variant="contained" onClick={handleSaveClick}>Save</Button>
                            }
                            <Button variant="outlined" onClick={handleCancelClick}>
                                {formMode === FormMode.ViewMode ? 'Close' : 'Cancel'}
                            </Button>
                        </Stack>

                    </Box>
                </Grid>
            </Grid>
        </Paper>

    );
}