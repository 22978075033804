import React from 'react';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { FormMode } from '../utils/extensions/constantEx'

const NurseMenuPopup = (props) => {
    const { nurseRow } = props;
    const navigate = useNavigate();

    const handleNewServiceClick = (popupState) => {
        popupState.close();
        navigate("/service-form", {
            replace: false,
            state: {
                from: 'nurse-search',
                formMode: FormMode.NewMode,
                selectedNurseId: nurseRow.id
            }
        });
    }

    const handleViewServiceClick = (popupState) => {
        popupState.close();
        navigate("/service-form", { replace: false });
    }

    const handleNewSalaryPaymentClick = (popupState) => {
        popupState.close();
        navigate("/nurses-salary-form", { replace: false });
    }

    const handleViewSalaryPaymentClick = (popupState) => {
        popupState.close();
        navigate("/nurses-salary-payments", { replace: false });
    }

    const handleEditClick = (popupState) => {
        popupState.close();
        navigate("/nurse-form", {
            replace: false,
            state: {
                formMode: FormMode.ViewMode,
                from: 'nurse-search',
                selectedId: nurseRow.id
            }
        });
    }

    const handleDeleteClick = (popupState) => {
        popupState.close();
    }

    return (
        <PopupState variant="popover" popupId="nurse-search-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => handleNewServiceClick(popupState)} disableRipple>
                            New Service
                        </MenuItem>
                        <MenuItem onClick={() => handleViewServiceClick(popupState)} disableRipple>
                            View Service
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem onClick={() => handleNewSalaryPaymentClick(popupState)} disableRipple>
                            New Salary Payment
                        </MenuItem>
                        <MenuItem onClick={() => handleViewSalaryPaymentClick(popupState)} disableRipple>
                            View Salary Payments
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem onClick={() => handleEditClick(popupState)} disableRipple>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteClick(popupState)} disableRipple>
                            Delete
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
}
export default NurseMenuPopup;