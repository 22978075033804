import React, { useState, useEffect, useContext } from 'react';
import { Stack, Autocomplete, Typography, TextField, Grid, IconButton } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { AccordionSummary, AccordionDetails, Accordion } from '../shared/components/accordion';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormMode, GenderList } from '../utils/extensions/constantEx';
import { GetDayJSValueFromUTCDate } from '../utils/extensions/functionEx';
import { HomeContext } from '../utils/contexts/homeContext';
import DocumentForm from '../shared/components/documentForm';
import dayjs from 'dayjs';

const NursePersonalnfo = (props) => {
    const { OpenErrorAlert, ShowAPIError, homeState } = useContext(HomeContext);
    const { formOpenMode, expanded, isShowValidationMsgs, religions, selectedNurse } = props;
    const { handlePersonalInfoFormValueChange, handleAccordionChange } = props;

    const [cleared, setCleared] = useState(false);
    const [dob, setDOB] = useState(null);
    const [age, setAge] = useState(0);
    const [selectedGender, setSelectedGender] = useState(GenderList[1]);
    const [spouseName, setSpouseName] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [motherName, setMotherName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [policeStation, setPoliceStation] = useState('');
    const [education, setEducation] = useState('');
    const [languagesKnown, setLanguagesKnown] = useState('');
    const [height, setHeight] = useState('');
    const [colour, setColour] = useState('');
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [bankAccount, setBankAccount] = useState('');
    const [notes, setNotes] = useState('');
    const [aadhaar, setAadhaar] = useState('');
    const [isAadharDocForm, setIsAadharDocForm] = React.useState(false);
    const [aadharUploadedDoc, setAadharUploadedDoc] = React.useState(null);

    useEffect(() => {
        if (formOpenMode !== FormMode.EditMode) {
            setDOB(null);
            setAge(0);
            setSelectedGender(GenderList[1]);
            setSpouseName('');
            setFatherName('');
            setMotherName('');
            setAddress1('');
            setAddress2('');
            setPoliceStation('');
            setEducation('');
            setLanguagesKnown('');
            setHeight('');
            setColour('');
            setSelectedReligion(null);
            setBankAccount('');
            setNotes('');
            setAadhaar('');
            setIsAadharDocForm(false);
            setAadharUploadedDoc(null);
        }
    }, [formOpenMode]);

    useEffect(() => {
        if (selectedNurse && selectedNurse.id > 0) {
            setSpouseName(selectedNurse.spouse_name);
            setFatherName(selectedNurse.father_name);
            setMotherName(selectedNurse.mother_name);
            setAddress1(selectedNurse.address1);
            setAddress2(selectedNurse.address2);
            setPoliceStation(selectedNurse.police_station);
            setEducation(selectedNurse.education);
            setLanguagesKnown(selectedNurse.police_station);
            setHeight(selectedNurse.height.toString());
            setColour(selectedNurse.colour);
            setBankAccount(selectedNurse.bank_account);
            setNotes(selectedNurse.notes);
            setDOB(GetDayJSValueFromUTCDate(selectedNurse.dob));

            const tempReligions = religions.filter(col => col.id === selectedNurse.religion_id);
            if (tempReligions.length > 0) {
                setSelectedReligion(tempReligions[0]);
            }

            if (GenderList[1].id === selectedNurse.gender_id) {
                setSelectedGender(GenderList[1]);
            } else if (GenderList[0].id === selectedNurse.gender_id) {
                setSelectedGender(GenderList[0]);
            }
            const tempDbDocs = selectedNurse.documents.filter(col => col.document_type === 'Aadhaar');
            if (tempDbDocs.length > 0) {
                setAadharUploadedDoc({
                    documentType: "Aadhaar",
                    fileName: tempDbDocs[0].file_name,
                    fileType: tempDbDocs[0].file_type,
                    id: tempDbDocs[0].id,
                    no: tempDbDocs[0].document_no,
                    uploadedFile: null
                });
            }
        }

    }, [selectedNurse]);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    useEffect(() => {
        let tempAge = 0;
        if (dob) {
            tempAge = dayjs().year() - dob.year();
        }
        setAge(tempAge);
        handleFormValueChange();
    }, [dob]);

    useEffect(() => {
        handleFormValueChange();

        if (aadharUploadedDoc) {
            setAadhaar(aadharUploadedDoc.no);
        } else {
            setAadhaar('');
        }
    }, [selectedGender, selectedReligion, colour, spouseName, fatherName, motherName, address1, aadharUploadedDoc,
        address2, policeStation, education, languagesKnown, notes, bankAccount, height
    ]);

    const handleFormValueChange = () => {
        try {
            let personalForm = {};

            personalForm.dob = dob;
            personalForm.colour = colour.trim();
            personalForm.spouseName = spouseName.trim();
            personalForm.fatherName = fatherName.trim();
            personalForm.motherName = motherName.trim();
            personalForm.address1 = address1.trim();
            personalForm.address2 = address2.trim();
            personalForm.policeStation = policeStation.trim();
            personalForm.education = education.trim();
            personalForm.languagesKnown = languagesKnown.trim();
            personalForm.bankAccount = bankAccount.trim();
            personalForm.notes = notes.trim();
            personalForm.height = height.trim();
            personalForm.aadhaarNo = aadhaar.trim();
            personalForm.aadhaarDocument = aadharUploadedDoc;

            personalForm.genderId = selectedGender && selectedGender.id ? selectedGender.id : 0;
            personalForm.religionId = selectedReligion && selectedReligion.id ? selectedReligion.id : 0;

            handlePersonalInfoFormValueChange(personalForm);
        }
        catch (error) {
            OpenErrorAlert(error);
        }
    }

    const handleOpenAadharUploadClick = () => {
        setIsAadharDocForm(true);
    }

    const handleAadharDocCancelClick = () => {
        setIsAadharDocForm(false);
    }

    const handleAadharDocSaveClick = (aadharDoc) => {
        if (aadharDoc && aadharDoc.uploadedFile) {
            const documentId = aadharDoc.id;
            setAadharUploadedDoc({ ...aadharDoc, id: -100, documentId: documentId });
        }
        setIsAadharDocForm(false);
    }


    return (

        <Accordion expanded={expanded === 'panelPersonalInfo'} onChange={handleAccordionChange('panelPersonalInfo')}>
            <AccordionSummary id="panel-personal-info">
                <Typography>Personal Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <DatePicker readOnly={formOpenMode === FormMode.ViewMode}
                                label="DOB" maxDate={dayjs().add(-18, 'year')} minDate={dayjs().add(-80, 'year')}
                                value={dob}
                                onChange={(newValue) => { setDOB(newValue); handleFormValueChange(); }}
                                slotProps={{
                                    textField: {
                                        size: "small", fullWidth: true, required: true,
                                        error: isShowValidationMsgs && !dob ? true : false,
                                        helperText: isShowValidationMsgs && !dob ? 'Enter DOB' : 'MM/DD/YYYY'
                                    },
                                    field: {
                                        clearable: true,
                                        onClear: () => setCleared(true)
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" fullWidth
                                label="Age" type="number" value={age}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete readOnly={formOpenMode === FormMode.ViewMode}
                                id="combo-box-gender" disableClearable
                                options={GenderList} value={selectedGender}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                    setSelectedGender(newValue);
                                    handleFormValueChange();
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Gender" size="small" fullWidth required
                                        error={isShowValidationMsgs && !selectedGender ? true : false}
                                        helperText={isShowValidationMsgs && !selectedGender ? 'Select gender' : ''} />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete readOnly={formOpenMode === FormMode.ViewMode}
                                id="combo-box-religion"
                                options={religions}
                                value={selectedReligion}
                                getOptionLabel={(option) => option.religion_name}
                                onChange={(event, newValue) => {
                                    setSelectedReligion(newValue);
                                    handleFormValueChange();
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Religion" size="small" fullWidth required
                                        error={isShowValidationMsgs && !selectedReligion ? true : false}
                                        helperText={isShowValidationMsgs && !selectedReligion ? 'Select religion' : ''}
                                    />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" fullWidth
                                label="Height"
                                value={height}
                                onChange={(event) => {
                                    setHeight(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" fullWidth
                                label="Colour"
                                value={colour}
                                onChange={(event) => {
                                    setColour(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField size="small" fullWidth
                                label="Spouse Name"
                                value={spouseName}
                                onChange={(event) => {
                                    setSpouseName(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField size="small" fullWidth
                                label="Father Name"
                                value={fatherName}
                                onChange={(event) => {
                                    setFatherName(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField size="small" fullWidth
                                label="Mother Name"
                                value={motherName}
                                onChange={(event) => {
                                    setMotherName(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField size="small" fullWidth
                                label="Address 1"
                                helperText=""
                                multiline
                                rows={4}
                                value={address1}
                                onChange={(event) => {
                                    setAddress1(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField size="small" fullWidth
                                label="Address 2"
                                helperText=""
                                multiline
                                rows={4}
                                value={address2}
                                onChange={(event) => {
                                    setAddress2(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField size="small" fullWidth
                                label="Police Station"
                                value={policeStation}
                                onChange={(event) => {
                                    setPoliceStation(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" spacing={1}>
                                <TextField size="small" fullWidth
                                    label="Aadhaar No"
                                    value={aadhaar}
                                    onChange={(event) => {
                                        setAadhaar(event.target.value);
                                        handleFormValueChange();
                                    }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    inputProps={{
                                        maxlength: 20
                                    }}
                                />

                                {
                                    formOpenMode !== FormMode.ViewMode && !aadharUploadedDoc &&
                                    <IconButton onClick={handleOpenAadharUploadClick}>
                                        <FilePresentIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                {
                                    isAadharDocForm &&
                                    <DocumentForm isOpen={isAadharDocForm} isProfilePhoto={false} isAadhaarDoc={true} loggedUser={homeState.loggedUser}
                                        handleSaveClick={handleAadharDocSaveClick}
                                        handleCancelClick={handleAadharDocCancelClick}
                                        showAPIError={ShowAPIError} openErrorAlert={OpenErrorAlert} />
                                }
                            </Stack>

                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField size="small" fullWidth
                                label="Education"
                                value={education}
                                onChange={(event) => {
                                    setEducation(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField size="small" fullWidth
                                label="Languages Known"
                                value={languagesKnown}
                                onChange={(event) => {
                                    setLanguagesKnown(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>

                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField size="small" fullWidth multiline
                                label="Bank Account"
                                helperText=""
                                rows={5}
                                value={bankAccount}
                                onChange={(event) => {
                                    setBankAccount(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField size="small" fullWidth multiline
                                label="Notes"
                                helperText=""
                                rows={5}
                                value={notes}
                                onChange={(event) => {
                                    setNotes(event.target.value);
                                    handleFormValueChange();
                                }}
                                InputProps={{
                                    readOnly: formOpenMode === FormMode.ViewMode
                                }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </AccordionDetails>
        </Accordion>

    );
}

export default NursePersonalnfo;
