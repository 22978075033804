import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, Grid, TextField, Divider, Autocomplete, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ServiceFormHeader from './serviceFormHeader';
import PatientFamilyView from './patientFamilyView'
import PatientDocumentView from './patientDocumentView';
import { HomeContext } from '../utils/contexts/homeContext';
import { ConstantValues, FormMode } from '../utils/extensions/constantEx'
import { GetDayJSValueFromUTCDate, GetDateFromDayJSValue, GetFullPhoneNo, GetFormattedNo } from '../utils/extensions/functionEx';
import PageLoading from '../shared/components/backdrop';
import { GetServiceTypesAPIUrl, NurseAPIUrl, DocumentAPIUrl } from '../utils/extensions/functionEx';
import dayjs from 'dayjs';

export default function ServiceForm(props) {
    const { ShowAPIError, OpenErrorAlert, OpenSuccessAlert, homeState } = useContext(HomeContext);

    const location = useLocation();
    const navigate = useNavigate();

    const [cleared, setCleared] = useState(false);
    const [expanded, setExpanded] = useState('');
    const [isOpenPageLoad, setIsOpenPageLoad] = useState(false);
    const [formOpenMode, setFormOpenMode] = useState(FormMode.NewMode);
    const [isShowValidationMsgs, setIsShowValidationMsgs] = useState(false);
    const [selectedNurse, setSelectedNurse] = useState(null);
    const [patientName, setPatientName] = useState('');
    const [patientMobileNo, setPatientMobileNo] = useState('');
    const [patientPlace, setPatientPlace] = useState('');
    const [patientAddress, setPatientAddress] = useState('');
    const [patientBusRoute, setPatientBusRoute] = useState('');
    const [patientPhone1, setPatientPhone1] = useState('');
    const [patientPhone2, setPatientPhone2] = useState('');
    const [selectedServiceType, setSelectedServiceType] = useState(null);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [serviceStartDate, setServiceStartDate] = useState(null);
    const [serviceEndDate, setServiceEndDate] = useState(null);
    const [serviceDutyDays, setServiceDutyDays] = useState(0);
    const [serviceExtDutyDays, setServiceExtDutyDays] = useState(0);
    const [serviceTotalFee, setServiceTotalFee] = useState(0);
    const [servicePaidAmount, setServicePaidAmount] = useState(0);
    const [serviceDiscountAmount, setServiceDiscountAmount] = useState(0);
    const [serviceRefundAmount, setServiceRefundAmount] = useState(0);
    const [serviceBalanceAmount, setServiceBalanceAmount] = useState(0);
    const [nurseStartDate, setNurseStartDate] = useState(null);
    const [nurseEndDate, setNurseEndDate] = useState(null);
    const [nurseDutyDays, setNurseDutyDays] = useState(0);
    const [nurseExtDutyDays, setNurseExtDutyDays] = useState(0);
    const [nursePaidAmount, setNursePaidAmount] = useState(0);
    const [nurseAdvanceAmount, setNurseAdvanceAmount] = useState(0);

    useEffect(() => {

        async function fetchServiceTypes() {
            try {
                const response = await fetch(GetServiceTypesAPIUrl())
                if (response.status === 200) {
                    setServiceTypes(await response.json());
                } else {
                    ShowAPIError(response)
                }
            }
            catch (error) {
                OpenErrorAlert(error)
            }
        }

        setIsOpenPageLoad(true);
        fetchServiceTypes();

        if (location && location.state && location.state.selectedNurseId && location.state.selectedNurseId > 0) {
            setFormMode(location.state.formMode);
            getNurse(location.state.selectedNurseId);
        } else {
            setFormMode(FormMode.NewMode);
        }
        handlePageLoadingClose();
    }, [location]);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    const setFormMode = (tempFrmMode) => {
        setFormOpenMode(tempFrmMode);
        clearForm();
        // if (tempFrmMode === FormMode.NewMode) {
        //     const tempNurse = {
        //         id: 0
        //     };
        //     setSelectedNurse({ ...tempNurse });
        //     setNurseFormValue({ ...tempNurse });
        // }
    }

    const clearForm = () => {
        setSelectedServiceType(null);
        setPatientName('');
        setPatientMobileNo('');
        setPatientPlace('');
        setServiceStartDate(null);
        setServiceEndDate(null);
        setSelectedNurse(null);
        setServiceDutyDays(0);
        setServiceExtDutyDays(0);
        setServiceTotalFee(0);
        setServicePaidAmount(0);
        setServiceDiscountAmount(0);
        setServiceRefundAmount(0);
        setServiceBalanceAmount(0);
        setNurseStartDate(null);
        setNurseEndDate(null);
        setNurseDutyDays(0);
        setNurseExtDutyDays(0);
        setNursePaidAmount(0);
        setNurseAdvanceAmount(0);
        setPatientAddress('');
        setPatientBusRoute('');
        setPatientPhone1('');
        setPatientPhone2('');
        setExpanded('');
        setIsShowValidationMsgs(false);
    }

    const handlePageLoadingClose = () => {
        setIsOpenPageLoad(false);
    }

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    const handleEditClick = (id) => {
        setFormMode(FormMode.EditMode);
    }

    const handleSaveClick = () => {

    }

    const handleCancelClick = () => {

    }

    const getNurse = async (nurseId) => {
        try {
            const response = await fetch(NurseAPIUrl(homeState.loggedUser.companyCode, nurseId));
            if (response.status === 200) {
                const tempNurseJson = await response.json();
                const tempNurse = {
                    id: tempNurseJson.id,
                    code: tempNurseJson.code,
                    nurseName: tempNurseJson.nurse_name,
                    phoneNo: GetFullPhoneNo(tempNurseJson.phone1, tempNurseJson.phone2, tempNurseJson.phone3)
                };
                setSelectedNurse(tempNurse);
            } else {
                setSelectedNurse(null);
                ShowAPIError(response);
            }
        }
        catch (error) {
            setSelectedNurse(null);
            OpenErrorAlert(error);
        }
    }

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-in"}>

                <Stack spacing={0}>
                    {
                        isOpenPageLoad &&
                        <PageLoading isOpenPageLoading={isOpenPageLoad} handleClose={handlePageLoadingClose} />
                    }
                    <ServiceFormHeader formMode={formOpenMode} handleEditClick={handleEditClick}
                        handleSaveClick={handleSaveClick}
                        handleCancelClick={handleCancelClick} />
                    <Box sx={{ padding: "16px", maxHeight: '80vh', overflowY: 'auto' }}>
                        <Stack>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <TextField size="small" fullWidth
                                            label="Code"
                                            helperText=""
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField size="small" fullWidth required
                                            label="Patient Name"
                                            error={isShowValidationMsgs && patientName.trim().length === 0 ? true : false}
                                            helperText={isShowValidationMsgs && patientName.trim().length === 0 ? 'Enter patient name' : ''}
                                            value={patientName}
                                            onChange={(event) => {
                                                setPatientName(event.target.value);
                                            }}
                                            InputProps={{
                                                readOnly: formOpenMode === FormMode.ViewMode
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth required
                                            label="Mobile No"
                                            error={isShowValidationMsgs && patientMobileNo.trim().length === 0 ? true : false}
                                            helperText={isShowValidationMsgs && patientMobileNo.trim().length === 0 ? 'Enter patient mobile no' : ''}
                                            value={patientMobileNo}
                                            onChange={(event) => {
                                                setPatientMobileNo(event.target.value);
                                            }}
                                            InputProps={{
                                                readOnly: formOpenMode === FormMode.ViewMode
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField size="small" fullWidth required
                                            label="Place"
                                            error={isShowValidationMsgs && patientPlace.trim().length === 0 ? true : false}
                                            helperText={isShowValidationMsgs && patientPlace.trim().length === 0 ? 'Enter patient place' : ''}
                                            value={patientPlace}
                                            onChange={(event) => {
                                                setPatientPlace(event.target.value);
                                            }}
                                            InputProps={{
                                                readOnly: formOpenMode === FormMode.ViewMode
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            id="combo-box-service-type"
                                            options={serviceTypes} value={selectedServiceType}
                                            getOptionLabel={(option) => option.service_type_name}
                                            onChange={(event, newValue) => {
                                                setSelectedServiceType(newValue);
                                            }}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Service Type" size="small" fullWidth required
                                                    error={isShowValidationMsgs && !selectedServiceType ? true : false}
                                                    helperText={isShowValidationMsgs && !selectedServiceType ? 'Select service type' : ''}
                                                />}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <DatePicker readOnly={formOpenMode === FormMode.ViewMode}
                                            label="Start Date" maxDate={dayjs()} minDate={dayjs().add(-3, 'day')}
                                            alue={serviceStartDate}
                                            onChange={(newValue) => { setServiceStartDate(newValue); }}
                                            slotProps={{
                                                textField: {
                                                    size: "small", fullWidth: "true", required: "true",
                                                    error: isShowValidationMsgs && !serviceStartDate ? true : false,
                                                    helperText: isShowValidationMsgs && !serviceStartDate ? 'Enter start date' : 'MM/DD/YYYY'
                                                },
                                                field: {
                                                    clearable: true,
                                                    onClear: () => setCleared(true)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField size="small" fullWidth required type='number'
                                            label="Duty Days"
                                            error={isShowValidationMsgs && serviceDutyDays <= 0 ? true : false}
                                            helperText={isShowValidationMsgs && serviceDutyDays <= 0 ? 'Enter duty days' : ''}
                                            value={serviceDutyDays}
                                            onChange={(event) => {
                                                setServiceDutyDays(event.target.value ?? 0);
                                            }}
                                            InputProps={{
                                                readOnly: formOpenMode === FormMode.ViewMode
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth type='number'
                                            label="Extended Duty Days"
                                            error={isShowValidationMsgs && serviceExtDutyDays <= 0 ? true : false}
                                            helperText={isShowValidationMsgs && serviceExtDutyDays < 0 ? 'Enter extended duty days' : ''}
                                            value={serviceExtDutyDays}
                                            onChange={(event) => {
                                                setServiceExtDutyDays(event.target.value ?? 0);
                                            }}
                                            InputProps={{
                                                readOnly: formOpenMode === FormMode.ViewMode
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth required
                                            label="End Date" value={serviceEndDate}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth 
                                            label="Total Fee"
                                            error={isShowValidationMsgs && serviceTotalFee <= 0 ? true : false}
                                            helperText={isShowValidationMsgs && serviceTotalFee <= 0 ? 'Enter total fee' : ''}
                                            value={GetFormattedNo(serviceTotalFee)}
                                            onChange={(event) => {
                                                setServiceTotalFee(event.target.value ?? 0);
                                            }}
                                            InputProps={{
                                                readOnly: formOpenMode === FormMode.ViewMode
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="Paid Amount" value={GetFormattedNo(servicePaidAmount)}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="Discount Amount" value={GetFormattedNo(serviceDiscountAmount)}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="Refund Amount" value={GetFormattedNo(serviceRefundAmount)}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="Balance Amount" value={GetFormattedNo(serviceBalanceAmount)}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                            <Box sx={{ "margin": "10px 0", paddingTop: "10px" }}>
                                <Stack>
                                    <Typography gutterBottom variant="subtitle2">
                                        Nurse Details
                                    </Typography>
                                    <Divider />
                                </Stack>

                            </Box>

                            <Box>
                                <Grid container spacing={1}>

                                    <Grid item xs={4}>
                                        <TextField size="small" fullWidth
                                            label="Nurse Name"
                                            value={selectedNurse ? selectedNurse.nurseName : ''}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField size="small" fullWidth
                                            label="Mobile No"
                                            value={selectedNurse ? selectedNurse.phoneNo : ''}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <DatePicker
                                            label="Start Date" readOnly={formOpenMode === FormMode.ViewMode}
                                            maxDate={dayjs()} minDate={serviceStartDate}
                                            value={nurseStartDate}
                                            onChange={(newValue) => { setNurseStartDate(newValue); }}
                                            slotProps={{
                                                textField: {
                                                    size: "small", fullWidth: "true", required: "true",
                                                    error: isShowValidationMsgs && !serviceStartDate ? true : false,
                                                    helperText: isShowValidationMsgs && !serviceStartDate ? 'Enter Start Date' : 'MM/DD/YYYY'
                                                },
                                                field: {
                                                    clearable: true,
                                                    onClear: () => setCleared(true)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="Duty Days"
                                            error={isShowValidationMsgs && nurseDutyDays <= 0 ? true : false}
                                            helperText={isShowValidationMsgs && nurseDutyDays <= 0 ? 'Enter Duty Days' : ''}
                                            value={nurseDutyDays}
                                            onChange={(event) => {
                                                setNurseDutyDays(event.target.value ?? 0);
                                            }}
                                            InputProps={{
                                                readOnly: formOpenMode === FormMode.ViewMode
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth type='number'
                                            label="Extended Duty Days"
                                            value={nurseDutyDays}
                                            onChange={(event) => {
                                                setNurseExtDutyDays(event.target.value ?? 0);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="End Date" value={nurseEndDate}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="Total Paid Salary"
                                            value={GetFormattedNo(nursePaidAmount)}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField size="small" fullWidth
                                            label="Advance Amount" value={GetFormattedNo(nurseAdvanceAmount)}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>

                            <Box sx={{ "margin": "10px 0", paddingTop: "10px" }}>
                                <Stack>
                                    <Typography gutterBottom variant="subtitle2">
                                        Patiant Details
                                    </Typography>
                                    <Divider />
                                </Stack>

                            </Box>

                            <Box>
                                <Grid container spacing={1}>

                                    <Grid item xs={4}>
                                        <TextField size="small" fullWidth
                                            label="Address"
                                            helperText=""
                                            multiline
                                            rows={4}
                                            value={patientAddress}
                                            onChange={(event) => {
                                                setPatientAddress(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField size="small" fullWidth
                                            label="Bus Route"
                                            helperText=""
                                            multiline
                                            rows={4} value={patientBusRoute}
                                            onChange={(event) => {
                                                setPatientBusRoute(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Stack spacing={2}>
                                            <TextField size="small" fullWidth label="Phone 1"
                                                value={patientPhone1}
                                                onChange={(event) => {
                                                    setPatientPhone1(event.target.value);
                                                }}
                                            />

                                            <TextField size="small" fullWidth label="Phone 2"
                                                value={patientPhone2}
                                                onChange={(event) => {
                                                    setPatientPhone2(event.target.value);
                                                }}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box sx={{ padding: "16px 0" }}>
                                            <PatientFamilyView patientFamilies={[]} expanded={expanded}
                                                handleAccordionChange={handleAccordionChange} />

                                            <PatientDocumentView patientDocuments={[]} expanded={expanded}
                                                handleAccordionChange={handleAccordionChange} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </LocalizationProvider>
        </Box>

    );
}