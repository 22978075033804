import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Paper, Grid, Typography, Button, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { HomeContext } from '../utils/contexts/homeContext';
import { FormMode } from '../utils/extensions/constantEx'

const NurseHeader = (props) => {
    const { handleNurseSearchClick } = props;
    const { homeState } = useContext(HomeContext);

    const navigate = useNavigate();

    const [searchText, setSearchText] = useState(homeState.nurseSearchText.length > 0 ? homeState.nurseSearchText : '');

    const handleNewNurseClick = () => {
        navigate("/nurse-form", {
            replace: false,
            state: {
                formMode: FormMode.NewMode,
                from: 'nurse-search',
                selectedId: 0
            }
        });
    }

    const handleSearchClick = () => {
        const tempSearchText = searchText.trim();
        setSearchText(tempSearchText);
        handleNurseSearchClick(tempSearchText);
    }

    return (
        <Paper>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item xs={2}>
                    <Typography variant="h5" sx={{ p: 2 }}>
                        Nurses
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Paper variant="outlined"
                        sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                    >
                        <InputBase value={searchText}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Nurse"
                            onChange={(event) => {
                                setSearchText(event.target.value);
                            }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search nurse" onClick={handleSearchClick}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{ pr: 1, textAlign: 'right', paddingTop: '14px' }}>
                        <Button variant="outlined" onClick={handleNewNurseClick}>Add Nurse</Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>

    );
}
export default NurseHeader;