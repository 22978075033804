import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import ReminderPage from './reminder';
import NurseSearch from '../nurses/nurseSearch';
import NurseForm from '../nurses/nurseForm';
import ServiceSearch from '../services/serviceSearch';
import ServiceForm from '../services/serviceForm';
import NursePaymentSearch from '../nurse-salary-payments/nursePaymentSearch';
import NursePaymentForm from '../nurse-salary-payments/nursePaymentForm';
import PaymentSearch from '../service-payments/paymentSearch';
import PaymentForm from '../service-payments/paymentForm';
import { FormMode } from '../utils/extensions/constantEx'

const PageLoader = () => {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    );
}

export default function PageRoute() {

    return (
        <React.Fragment>
            <Routes>
                <Route path="/" element={<Navigate to="/reminder" replace={true} />} />
                <Route
                    path="reminder"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <ReminderPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/search-nurses"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <NurseSearch />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/nurse-form"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <NurseForm formMode={FormMode.NewMode} />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/nurses-salary-payments"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <NursePaymentSearch />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/nurses-salary-form"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <NursePaymentForm />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/search-services"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <ServiceSearch />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/service-form"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <ServiceForm />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/service-payments"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <PaymentSearch />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/service-payment-form"
                    element={
                        <React.Suspense fallback={<PageLoader />}>
                            <PaymentForm />
                        </React.Suspense>
                    }
                />

            </Routes>
        </React.Fragment>
    );
}