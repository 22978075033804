import { CommonUrls } from './constantEx';
import { API_BASE_URL } from '../../startups/apiConfig';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc' // import plugin

dayjs.extend(utc);

export const GetDateOnly = (dateTimeValue) => {
    return new Date(dateTimeValue.getYear(), dateTimeValue.getMonth(), dateTimeValue.getDate(), 0, 0, 0);
}

export const CompanyAPIUrl = (companyCode) => API_BASE_URL + CommonUrls.Company.replace('<code>', companyCode);

export const GetReligionAPIUrl = () => API_BASE_URL + CommonUrls.GetReligions;

export const GetServiceTypesAPIUrl = () => API_BASE_URL + CommonUrls.ServiceTypes;

export const DocumentAPIUrl = (companyCode, docId = 0) => {
    const tempDocUrl = CompanyAPIUrl(companyCode) + CommonUrls.Document;
    return tempDocUrl + (docId > 0 ? ('?doc_id=' + docId.toString()) : '').trim();
}

export const GetNurseSeachAPIUrl = (companyCode, searchText) => {
    const tempUrl = CompanyAPIUrl(companyCode) + CommonUrls.NurseSeach;
    return tempUrl.replace('<search-text>', searchText);
}

export const NurseAPIUrl = (companyCode, nurseId = 0) => {
    const nurseAPI = CompanyAPIUrl(companyCode) + CommonUrls.Nurse;
    return nurseAPI + (nurseId > 0 ? ('?nurse_id=' + nurseId.toString()) : '').trim();
}

export const GetDateFromDayJSValue = (dayJSvalue) => dayJSvalue ? dayJSvalue.toDate() : null;

export const GetDayJSValueFromUTCDate = (utcDate) => dayjs.utc(utcDate).local();

export const GetFormattedNo = (numberValue) => new Intl.NumberFormat('en-IN').format(numberValue.toString().replace(',', ''));

export const GetFullPhoneNo = (phone1, phone2 = '', phone3 = '') => {
    let tempPhone = '';

    if (phone1 && phone1.trim().length > 0) {
        tempPhone = phone1.trim();
    }
    if (phone2 && phone2.trim().length > 0) {
        if (tempPhone.length > 0) {
            tempPhone += ', ';
        }
        tempPhone += phone2.trim();
    }
    if (phone3 && phone3.trim().length > 0) {
        if (tempPhone.length > 0) {
            tempPhone += ', ';
        }
        tempPhone += phone3.trim();
    }
    return tempPhone.trim();
}