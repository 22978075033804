import React, { useMemo } from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import Slide from '@mui/material/Slide';
// import Alert from '@mui/material/Alert';
import { Box, Stack, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Alert } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { DocumentAPIUrl } from '../../utils/extensions/functionEx';
import { ACCOUNT_ICON_DOC_ID } from '../../startups/apiConfig';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    height: '100px',
    width: '125px'
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function fileSizeValidator(file) {
    const tempSizeMB = (file.size / 1024) / 1024;
    if (tempSizeMB > 7) {
        return {
            code: "file-size-too-large",
            message: `File size is too large, not more than 5 MB.`
        };
    }

    return null
}

const FileUpload = (props) => {
    const { handleUploadFile } = props;

    const [files, setFiles] = React.useState([]);

    const handleDeleteClick = () => {
        setFiles([]);
    }

    const { getRootProps, getInputProps, isFocused, isDragAccept, fileRejections } = useDropzone({
        disabled: false,
        noKeyboard: true,
        maxFiles: 1,
        accept: {
            'image/*': ['.jpeg', '.png'],
        },
        validator: fileSizeValidator,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <Stack spacing={1}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                    <Button variant="contained" color="error" size="small" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Stack>

            </div>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        errors && errors.length > 0 && <Alert severity="error">{errors[0].message}</Alert>
    ));

    React.useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    React.useEffect(() => {
        if (files && files.length > 0) {
            handleUploadFile(files[0]);
            return;
        }
        handleUploadFile(null);
    }, [files]);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {})
    }), [
        isFocused,
        isDragAccept
    ]);


    return (
        <section className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some image here, or click to select iamge</p>
            </div>
            <aside style={thumbsContainer}>
                {thumbs}
                {fileRejectionItems}
            </aside>
        </section>
    );
}

const DocumentForm = (props) => {
    const { isOpen, isProfilePhoto, isAadhaarDoc, loggedUser, selectedDocument,
        handleSaveClick, handleCancelClick, showAPIError, openErrorAlert } = props;

    const [uploadedFile, setUploadedFile] = React.useState(null);
    const [documentType, setDocumentType] = React.useState(isProfilePhoto ? 'Profile Photo' : (isAadhaarDoc ? 'Aadhaar' : ''));
    const [docNo, setDocNo] = React.useState('');
    const [fileType, setFileType] = React.useState('');
    const [fileName, setFileName] = React.useState('');

    const isSaveButtonEnabled = documentType.length > 0;

    React.useEffect(() => {
        if (selectedDocument) {
            setUploadedFile(selectedDocument.uploadedFile);
            setDocumentType(selectedDocument.documentType);
            setDocNo(selectedDocument.no);
            setFileType(selectedDocument.fileType);
            setFileName(selectedDocument.fileName);
        }
    }, [selectedDocument]);

    const handleUploadFile = async (tempFile) => {
        setUploadedFile(null);
        if (tempFile) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                setUploadedFile(reader.result);
            });

            reader.readAsDataURL(tempFile);

            setFileName(tempFile.name);
            setFileType(tempFile.type);
        } else {
            setFileName('');
            setFileType('');
        }
    }

    const handleSaveButtonClick = async () => {
        try {
            const docFormValue = {
                id: parseInt(ACCOUNT_ICON_DOC_ID),
                no: docNo.trim(),
                uploadedFile: uploadedFile,
                documentType: documentType,
                fileName: fileName.trim(),
                fileType: fileType
            };

            if (!uploadedFile) {
                handleSaveClick(docFormValue);
                return;
            }

            const tempFileBody = {
                file_name: fileName.trim(),
                file_type: fileType,
                uploaded_file: uploadedFile
            };

            const fileHeaders = new Headers();
            fileHeaders.append("Accept", "application/json");
            fileHeaders.append("Accept-Encoding", "application/json");
            fileHeaders.append("Content-Type", "application/json");

            const response = await fetch(DocumentAPIUrl(loggedUser.companyCode), {
                method: "POST",
                headers: fileHeaders,
                body: JSON.stringify(tempFileBody)
            });
            if (response.status === 201) {
                const tempJsonDoc = await response.json();
                docFormValue.id = tempJsonDoc.id;

                handleSaveClick(docFormValue);
                return;
            }
            showAPIError(response);
        }
        catch (error) {
            openErrorAlert(error)
        }
    }

    return (
        <React.Fragment>
            <Dialog maxWidth={"sm"} fullWidth
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancelClick}
            >
                <DialogTitle>Upload New Document</DialogTitle>
                <DialogContent>
                    <Box sx={{ paddingTop: '14px' }}>
                        <Stack spacing={2}>
                            <TextField size="small" required
                                label="Document Type"
                                helperText={documentType.length == 0 ? "Enter valid document type (Voter ID, Driving Licence)" : ""}
                                value={documentType}
                                InputProps={{
                                    readOnly: isProfilePhoto || isAadhaarDoc
                                }}
                                onChange={(event) => {
                                    setDocumentType(event.target.value);
                                }}
                            />
                            {
                                !isProfilePhoto &&
                                <TextField size="small"
                                    label="No"
                                    value={docNo}
                                    helperText={docNo.length == 0 ? "Enter valid document number (Voter ID Number, Driving Licence Number)" : ""}
                                    InputProps={{
                                        readOnly: isProfilePhoto
                                    }}
                                    onChange={(event) => {
                                        setDocNo(event.target.value);
                                    }}
                                />
                            }
                            <TextField size="small"
                                label="Document Name"
                                helperText=""
                                value={fileName}
                                InputProps={{
                                    readOnly: true
                                }}
                            />

                            <FileUpload handleUploadFile={handleUploadFile} />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ paddingRight: "24px" }}>
                    <Button variant="contained" size="small"
                        disabled={!isSaveButtonEnabled}
                        onClick={handleSaveButtonClick}>Save</Button>

                    <Button variant="outlined" size="small" onClick={handleCancelClick}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DocumentForm;