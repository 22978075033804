import React, { useState } from 'react';
import { Box, Stack, Paper, Grid, Typography, Table, TableBody, TableContainer, TableHead, TableRow, Button, IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { AccordionSummary, AccordionDetails, Accordion } from '../shared/components/accordion';
import StyledTableRow from '../shared/components/styledTableRow';
import StyledTableCell from '../shared/components/styledTableCell';
import NurseFamilyForm from './nurseFamilyForm';
import ConfirmDialog from '../shared/components/confirmDialog';
import { FormMode } from '../utils/extensions/constantEx'

const NurseFamilyView = (props) => {
    const { formOpenMode, expanded, nurseFamilies, handleAccordionChange, handleNurseFamiliesChange } = props;

    const [isOpenNurseFamilyForm, setIsOpenNurseFamilyForm] = useState(false);
    const [nextFamilyId, setNextFamilyId] = useState(-1);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [selectedFamilyId, setSelectedFamilyId] = useState(0);
    const [selectedFamily, setSelectedFamily] = useState(null);

    const handleAddClick = () => {
        setIsOpenNurseFamilyForm(true);
    }

    const handleSaveClick = (nurseFamilyData) => {
        let tempNurseFamilies = [];

        if (nurseFamilyData.id === 0) {
            tempNurseFamilies = [...nurseFamilies];
            tempNurseFamilies.push({ ...nurseFamilyData, id: nextFamilyId });
        } else {
            let tempSelectedFamily = null;
            tempNurseFamilies = [...nurseFamilies.filter(col => col.id === nurseFamilyData.id)];

            if (tempNurseFamilies.length > 0) {
                tempSelectedFamily = {
                    ...tempNurseFamilies[0],
                    name: nurseFamilyData.name,
                    relation: nurseFamilyData.relation,
                    phoneNo: nurseFamilyData.phoneNo
                };
            }
            tempNurseFamilies = [...nurseFamilies.filter(col => col.id !== nurseFamilyData.id)];
            tempNurseFamilies.push(tempSelectedFamily);
        }
        handleNurseFamiliesChange([...tempNurseFamilies]);

        setNextFamilyId(nextFamilyId - 1);
        setIsOpenNurseFamilyForm(false);
        setSelectedFamily(null);
    }

    const handleCancelClick = () => {
        setIsOpenNurseFamilyForm(false);
        setSelectedFamily(null);
    }

    const handleDeleteClick = (familyId) => {
        setIsDeleteConfirmOpen(true);
        setSelectedFamilyId(familyId);
    }

    const handleDeleteCancelClick = () => {
        setIsDeleteConfirmOpen(false);
        setSelectedFamilyId(0);
    }

    const handleDeleteOKClick = () => {
        setIsDeleteConfirmOpen(false);
        handleNurseFamiliesChange([...nurseFamilies.filter(col => col.id !== selectedFamilyId)]);
        setSelectedFamilyId(0);
    }

    const handleEditClick = (familyId) => {
        let tempSelectedFamily = null;
        const tempNurseFamilies = [...nurseFamilies.filter(col => col.id === familyId)];

        if (tempNurseFamilies.length > 0) {
            tempSelectedFamily = { ...tempNurseFamilies[0] };
            setSelectedFamily(tempSelectedFamily);
        }
        setIsOpenNurseFamilyForm(true);
    }

    return (

        <Accordion expanded={expanded === 'panelNurseFamily'} onChange={handleAccordionChange('panelNurseFamily')}>
            <AccordionSummary id="panel-nurse-families">
                <Typography>Nurse Families</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box sx={{ pr: 1, float: 'right' }}>
                                <Stack direction="row" spacing={2}>
                                    {
                                        isDeleteConfirmOpen &&
                                        <ConfirmDialog isOpen={isDeleteConfirmOpen} title="Confirm Remove"
                                            message="Are you sure you want to remove the family details?"
                                            handleClickOK={handleDeleteOKClick}
                                            handleClickCancel={handleDeleteCancelClick} />

                                    }
                                    {
                                        isOpenNurseFamilyForm &&
                                        <NurseFamilyForm isOpen={isOpenNurseFamilyForm} selectedFamily={selectedFamily}
                                            handleSaveClick={handleSaveClick}
                                            handleCancelClick={handleCancelClick} />
                                    }
                                    {
                                        formOpenMode !== FormMode.ViewMode &&
                                        <Button variant="contained" onClick={handleAddClick}>Add</Button>
                                    }
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ maxHeight: '77vh' }}>
                                <Table stickyHeader sx={{ minWidth: 700 }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Relation</StyledTableCell>
                                            <StyledTableCell>Phone No</StyledTableCell>
                                            <StyledTableCell sx={{ width: '90px' }}>&nbsp;</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {nurseFamilies.map((row) => (
                                            <StyledTableRow key={row.id.toString()}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell>{row.relation}</StyledTableCell>
                                                <StyledTableCell>{row.phoneNo}</StyledTableCell>
                                                <StyledTableCell>
                                                    {
                                                        formOpenMode !== FormMode.ViewMode &&
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <IconButton size="small" onClick={() => handleEditClick(row.id)}>
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                            <IconButton size="small" onClick={() => handleDeleteClick(row.id)}>
                                                                <RemoveCircleOutlineIcon fontSize="small" />
                                                            </IconButton>
                                                        </Stack>
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Stack>
            </AccordionDetails>
        </Accordion>

    );
}
export default NurseFamilyView;
