import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function PageLoading(props) {
    const { isOpenPageLoading, handleClose } = props;

    const handlePageLoadingClose = () => {
        handleClose();
    };

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOpenPageLoading}
            onClick={handlePageLoadingClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}