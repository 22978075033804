import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, Grid, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import NurseFormHeader from './nurseFormHeader';
import NurseFamilyView from './nurseFamilyView';
import NursePersonalnfo from './nursePersonalnfo';
import NurseContact from './contact';
import NurseDocumentView from './nurseDocument';
import ProfilePhotoCard from '../shared/components/profilePhoto';
import DocumentForm from '../shared/components/documentForm';
import ConfirmDialog from '../shared/components/confirmDialog';
import { GetReligionAPIUrl, NurseAPIUrl, DocumentAPIUrl } from '../utils/extensions/functionEx';
import { HomeContext } from '../utils/contexts/homeContext';
import { ConstantValues, FormMode } from '../utils/extensions/constantEx'
import { GetDayJSValueFromUTCDate, GetDateFromDayJSValue } from '../utils/extensions/functionEx';
import PageLoading from '../shared/components/backdrop';
import { ACCOUNT_ICON_DOC_ID } from '../startups/apiConfig';
import dayjs from 'dayjs';

const NurseForm = (props) => {
    const { formMode } = props;
    const { ShowAPIError, OpenErrorAlert, OpenSuccessAlert, homeState } = useContext(HomeContext);

    const location = useLocation();
    const navigate = useNavigate();

    const [isOpenPageLoad, setIsOpenPageLoad] = useState(false);
    const [formOpenMode, setFormOpenMode] = useState(formMode ?? FormMode.NewMode);
    const [expanded, setExpanded] = useState('');
    const [cleared, setCleared] = useState(false);
    const [isShowValidationMsgs, setIsShowValidationMsgs] = useState(false);
    const [isOpenEditProfilePhoto, setIsOpenEditProfilePhoto] = React.useState(false);
    const [isOpenProfilePhotoDeleteConfirm, setiIsOpenProfilePhotoDeleteConfirm] = React.useState(false);
    const [nurseName, setNurseName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [isOnLeave, setIsOnLeave] = useState(false);
    const [rejoinDate, setRejoinDate] = useState(null);
    const [advanceAmount, setAdvanceAmount] = useState(0);
    const [profilePhotoFile, setProfilePhotoFile] = React.useState(null);
    const [selectedNurse, setSelectedNurse] = React.useState(null);
    const [religions, setReligions] = useState([]);
    const [nurseFamilies, setNurseFamilies] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [removedNurseDocIds, setRemovedNurseDocIds] = useState([]);
    const [removedDocIds, setRemovedDocIds] = useState([]);
    const [nurseFormValue, setNurseFormValue] = React.useState(null);

    useEffect(() => {

        async function fetchReligions() {
            try {
                const response = await fetch(GetReligionAPIUrl())
                if (response.status === 200) {
                    setReligions(await response.json());
                } else {
                    ShowAPIError(response)
                }
            }
            catch (error) {
                OpenErrorAlert(error)
            }
        }

        setIsOpenPageLoad(true);
        clearForm();
        fetchReligions();

        if (location && location.state && location.state.selectedId && location.state.selectedId > 0) {
            setFormMode(FormMode.ViewMode);
            getNurse(location.state.selectedId);
        } else {
            setFormMode(formMode ?? FormMode.NewMode);
        }
        handlePageLoadingClose();
    }, [location]);

    const setFormMode = (tempFrmMode) => {
        setFormOpenMode(tempFrmMode);
        if (tempFrmMode === FormMode.NewMode) {
            const tempNurse = {
                id: 0
            };
            setSelectedNurse({ ...tempNurse });
            setNurseFormValue({ ...tempNurse });
        }
    }

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    const handleOpenProfilePhotoEditClick = () => {
        setIsOpenEditProfilePhoto(true);
    }

    const handleOpenProfilePhotoDeleteClick = () => {
        setiIsOpenProfilePhotoDeleteConfirm(true);
    }

    const handleProfilePhotoSaveClick = (profileDocument) => {
        setProfilePhotoFile(profileDocument);
        setIsOpenEditProfilePhoto(false);
    }

    const handleProfilePhotoCancelClick = () => {
        setIsOpenEditProfilePhoto(false);
    }

    const handleProfilePhotoDeleteConfirmOKClick = () => {
        setProfilePhotoFile(null);
        setiIsOpenProfilePhotoDeleteConfirm(false);
    }

    const handleProfilePhotoDeleteConfirmCancelClick = () => {
        setiIsOpenProfilePhotoDeleteConfirm(false);
    }

    const getNurse = async (nurseId) => {
        try {
            let tempNurseJson = null;
            const response = await fetch(NurseAPIUrl(homeState.loggedUser.companyCode, nurseId));
            if (response.status === 200) {
                tempNurseJson = await response.json();
            } else {
                ShowAPIError(response)
            }

            if (tempNurseJson) {
                setSelectedNurse({ ...tempNurseJson });
                const tempNurse = {
                    id: tempNurseJson.id,
                    code: tempNurseJson.code,
                    nurse_name: tempNurseJson.nurse_name,
                    phone1: tempNurseJson.phone1,
                    phone2: tempNurseJson.phone2,
                    phone3: tempNurseJson.phone3,
                    dob: tempNurseJson.dob,
                    gender_id: tempNurseJson.gender_id,
                    spouse_name: tempNurseJson.spouse_name,
                    father_name: tempNurseJson.father_name,
                    mother_name: tempNurseJson.mother_name,
                    address1: tempNurseJson.address1,
                    address2: tempNurseJson.address2,
                    police_station: tempNurseJson.police_station,
                    education: tempNurseJson.education,
                    languages_known: tempNurseJson.languages_known,
                    height: tempNurseJson.height,
                    colour: tempNurseJson.colour,
                    religion_id: tempNurseJson.religion_id,
                    bank_account: tempNurseJson.bank_account,
                    aadhaar_no: '',
                    notes: tempNurseJson.notes,
                    relatives: [],
                    contacts: [],
                    documents: []
                };
                for (const tempContact of tempNurseJson.contacts) {
                    const tempViewContact = {
                        id: tempContact.id,
                        name: tempContact.contact_name,
                        address: tempContact.address,
                        phoneNo1: tempContact.phone1,
                        phoneNo2: tempContact.phone2
                    };
                    tempNurse.contacts.push(tempViewContact);
                }

                for (const tempRelative of tempNurseJson.relatives) {
                    const tempViewFamily = {
                        id: tempRelative.id,
                        name: tempRelative.relative_name,
                        relation: tempRelative.relationship,
                        phoneNo: tempRelative.phone_no
                    };
                    tempNurse.relatives.push(tempViewFamily);
                }
                for (const tempDbDoc of tempNurseJson.documents) {
                    const tempViewDoc = {
                        id: tempDbDoc.id,
                        documentId: tempDbDoc.document_id,
                        documentType: tempDbDoc.document_type,
                        no: tempDbDoc.document_no,
                        fileName: tempDbDoc.file_name,
                        fileType: tempDbDoc.file_type
                    };
                    tempNurse.documents.push(tempViewDoc);
                }

                setNurseFormValue({ ...tempNurse });

                setNurseName(tempNurseJson.nurse_name);
                setMobileNo(tempNurseJson.phone1);
                setPhone1(tempNurseJson.phone2);
                setPhone2(tempNurseJson.phone3);
                setAdvanceAmount(tempNurseJson.advance_amount);

                const tempRejoinDate = GetDayJSValueFromUTCDate(tempNurseJson.rejoin_date);
                if (tempRejoinDate.year() > 2000) {
                    setIsOnLeave(true);
                    setRejoinDate(tempRejoinDate);
                }
                setContacts([...tempNurse.contacts]);
                setNurseFamilies([...tempNurse.relatives]);
                setDocuments([...tempNurse.documents]);

                await getProfilePhotoFromDB(tempNurseJson.photo_id);
            }
        }
        catch (error) {
            OpenErrorAlert(error)
        }
    }

    const getProfilePhotoFromDB = async (db_photo_id) => {
        if (db_photo_id && db_photo_id > 0) {
            const response = await fetch(DocumentAPIUrl(homeState.loggedUser.companyCode, db_photo_id));
            if (response.status === 200) {
                const tempDocJson = await response.json();
                const tempProfilePhoto = {
                    uploadedFile: tempDocJson.uploaded_file,
                    id: tempDocJson.id,
                    fileName: tempDocJson.file_name,
                    fileType: tempDocJson.file_type
                };

                setProfilePhotoFile(tempProfilePhoto);
            }
        }
    }

    const handleSaveClick = async () => {
        try {
            let tempNurseId = 0;
            if (!isFormValid()) {
                setIsShowValidationMsgs(true);
                return;
            }
            setIsOpenPageLoad(true);

            if (formOpenMode === FormMode.NewMode) {
                tempNurseId = await addNewNurseAPI();
            } else if (formOpenMode === FormMode.EditMode) {
                tempNurseId = await updateNurseAPI();
            }

            if (tempNurseId > 0) {
                setFormMode(FormMode.ViewMode);
                clearForm();
                await getNurse(tempNurseId);
            }
        }
        catch (error) {
            OpenErrorAlert(error);
        }
        finally {
            handlePageLoadingClose();
        }
    }

    const isFormValid = () => {
        if (nurseName.trim().length === 0 || mobileNo.trim().length === 0
            || (isOnLeave && !rejoinDate)) {
            return false;
        }
        return true;
    }

    const getNurseFormValues = () => {
        const tempNurse = { ...nurseFormValue };
        tempNurse.nurse_name = nurseName.trim();
        tempNurse.phone1 = mobileNo.trim();
        tempNurse.phone2 = phone1.trim();
        tempNurse.phone3 = phone2.trim();
        tempNurse.rejoin_date = isOnLeave && rejoinDate ? GetDateFromDayJSValue(rejoinDate) : ConstantValues.DefaultDateValue;

        tempNurse.photo_id = 0;
        tempNurse.aadhaar_doc_id = 0;

        if (profilePhotoFile && profilePhotoFile.id !== parseInt(ACCOUNT_ICON_DOC_ID)) {
            tempNurse.photo_id = profilePhotoFile.id;
        }

        tempNurse.relatives = [];
        tempNurse.contacts = [];
        tempNurse.documents = [];
        tempNurse.removed_nurse_doc_ids = [...removedNurseDocIds];
        tempNurse.removed_doc_ids = [...removedDocIds];

        for (const tempFamily of nurseFamilies) {
            const tempDbFamily = {
                id: tempFamily.id,
                relative_name: tempFamily.name,
                relationship: tempFamily.relation,
                phone_no: tempFamily.phoneNo
            };
            tempNurse.relatives.push({ ...tempDbFamily });
        }

        for (const tempContact of contacts) {
            const tempDbContact = {
                id: tempContact.id,
                contact_name: tempContact.name,
                address: tempContact.address,
                phone1: tempContact.phoneNo1,
                phone2: tempContact.phoneNo2
            };
            tempNurse.contacts.push(tempDbContact);
        }
        for (const tempDoc of documents.filter(col => col.id < 0)) {
            const tempDbDoc = {
                id: tempDoc.id,
                document_id: tempDoc.documentId,
                document_type: tempDoc.documentType,
                document_no: tempDoc.no
            };
            tempNurse.documents.push(tempDbDoc);
        }
        setNurseFormValue(tempNurse)
        return { ...tempNurse };
    }

    const handlePersonalInfoFormValueChange = (personalFormValue) => {
        try {
            const tempNurse = { ...nurseFormValue };

            tempNurse.dob = GetDateFromDayJSValue(personalFormValue.dob);
            tempNurse.gender_id = personalFormValue.genderId;
            tempNurse.spouse_name = personalFormValue.spouseName;
            tempNurse.father_name = personalFormValue.fatherName;
            tempNurse.mother_name = personalFormValue.motherName;
            tempNurse.address1 = personalFormValue.address1;
            tempNurse.address2 = personalFormValue.address2;
            tempNurse.police_station = personalFormValue.policeStation;
            tempNurse.education = personalFormValue.education;
            tempNurse.languages_known = personalFormValue.languagesKnown;
            tempNurse.height = personalFormValue.height;
            tempNurse.colour = personalFormValue.colour;
            tempNurse.religion_id = personalFormValue.religionId;
            tempNurse.bank_account = personalFormValue.bankAccount;
            tempNurse.notes = personalFormValue.notes;

            if (personalFormValue.aadhaarDocument) {
                const tempDocuments = [...documents];
                const filteredDocs = tempDocuments.filter(col => col.documentType !== "Aadhaar");
                filteredDocs.unshift(personalFormValue.aadhaarDocument);
                setDocuments([...filteredDocs]);
            }

            setNurseFormValue(tempNurse);
        }
        catch (error) {
            OpenErrorAlert(error);
        }
    }

    const addNewNurseAPI = async () => {
        try {
            const tempNurse = getNurseFormValues();

            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Accept-Encoding", "application/json");
            headers.append("Content-Type", "application/json");

            const response = await fetch(NurseAPIUrl(homeState.loggedUser.companyCode), {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tempNurse)
            })
            if (response.status === 201) {
                OpenSuccessAlert('New nurse added successfully.');

                const tempJsonNurse = await response.json();
                return tempJsonNurse.id;
            }
            ShowAPIError(response);
            return -1;
        }
        catch (error) {
            OpenErrorAlert(error)
            return -1;
        }
    }

    const updateNurseAPI = async () => {
        try {
            const tempNurse = getNurseFormValues();
            
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Accept-Encoding", "application/json");
            headers.append("Content-Type", "application/json");

            const response = await fetch(NurseAPIUrl(homeState.loggedUser.companyCode), {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(tempNurse)
            });
            if (response.status === 200) {
                OpenSuccessAlert('Nurse details successfully updated.');

                const tempJsonNurse = await response.json();
                return tempJsonNurse.id;
            }
            ShowAPIError(response);
            return -1;
        }
        catch (error) {
            OpenErrorAlert(error)
            return -1;
        }
    }

    const handleNurseFamiliesChange = (families) => {
        setNurseFamilies([...families]);
    }

    const handleContactChange = (nurseContacts) => {
        setContacts([...nurseContacts]);
    }

    const handleDocumentChange = (docs) => {
        setDocuments([...docs]);
    }

    const handleRemovedNurseDocumentIdsChange = (removedNurseDocId, removedDocId) => {
        let tempRemovedIds = [];
        if (removedNurseDocId > 0) {
            tempRemovedIds = [...removedNurseDocIds];
            tempRemovedIds.push(removedNurseDocId);
            setRemovedNurseDocIds([...tempRemovedIds]);
        }
        if (removedDocId > 0) {
            tempRemovedIds = [...removedDocIds];
            tempRemovedIds.push(removedDocId);
            setRemovedDocIds([...tempRemovedIds]);
        }
    }

    const clearForm = () => {
        setNurseName('');
        setMobileNo('');
        setPhone1('');
        setPhone2('');
        setIsOnLeave(false);
        setRejoinDate(null);
        setAdvanceAmount(0);
        setProfilePhotoFile(null);
        setSelectedNurse(null);
        setNurseFamilies([]);
        setContacts([]);
        setDocuments([]);
        setRemovedNurseDocIds([]);
        setRemovedDocIds([]);
        setNurseFormValue(null);
        setExpanded('');
        setIsShowValidationMsgs(false);
    }

    const handleEditClick = () => {
        setFormMode(FormMode.EditMode);
    }

    const handleCancelClick = () => {
        if (formOpenMode === FormMode.ViewMode) {
            backToNurseSearch();
            return;
        }

        if (formOpenMode === FormMode.EditMode && selectedNurse && selectedNurse.id && selectedNurse.id > 0) {
            const tempId = selectedNurse.id;
            clearForm();
            setFormMode(FormMode.ViewMode);
            getNurse(tempId);
        }
        else {
            clearForm();
            setFormMode(FormMode.NewMode);
        }
    }

    const handlePageLoadingClose = () => {
        setIsOpenPageLoad(false);
    }

    const backToNurseSearch = () => {
        if (location && location.state && location.state.from && location.state.from === 'nurse-search') {
            navigate("/search-nurses", {
                replace: false
            });
            return;
        }
        clearForm();
        setFormMode(FormMode.NewMode);
    }

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-in"}>

                <Stack spacing={0}>
                    {
                        isOpenPageLoad &&
                        <PageLoading isOpenPageLoading={isOpenPageLoad} handleClose={handlePageLoadingClose} />
                    }
                    <NurseFormHeader formMode={formOpenMode} handleEditClick={handleEditClick}
                        handleSaveClick={handleSaveClick}
                        handleCancelClick={handleCancelClick} />
                    <Box sx={{ padding: "16px", maxHeight: '80vh', overflowY: 'auto' }}>
                        <Stack>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField size="small" fullWidth
                                                    label="File No"
                                                    helperText=""
                                                    value={nurseFormValue && nurseFormValue.code ? nurseFormValue.code : ''}
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField size="small" fullWidth required
                                                    label="Name"
                                                    error={isShowValidationMsgs && nurseName.trim().length === 0 ? true : false}
                                                    helperText={isShowValidationMsgs && nurseName.trim().length === 0 ? 'Enter nurse name' : ''}
                                                    value={nurseName}
                                                    onChange={(event) => {
                                                        setNurseName(event.target.value);
                                                        setIsShowValidationMsgs(false);
                                                    }}
                                                    InputProps={{
                                                        readOnly: formOpenMode === FormMode.ViewMode
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField size="small" fullWidth required
                                                    label="Mobile No"
                                                    error={isShowValidationMsgs && mobileNo.trim().length === 0 ? true : false}
                                                    helperText={isShowValidationMsgs && mobileNo.trim().length === 0 ? 'Enter mobile no' : ''}
                                                    value={mobileNo}
                                                    onChange={(event) => {
                                                        setMobileNo(event.target.value);
                                                        setIsShowValidationMsgs(false);
                                                    }}
                                                    InputProps={{
                                                        readOnly: formOpenMode === FormMode.ViewMode
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField size="small" fullWidth
                                                    label="Phone 1"
                                                    value={phone1}
                                                    onChange={(event) =>
                                                        setPhone1(event.target.value)}
                                                    InputProps={{
                                                        readOnly: formOpenMode === FormMode.ViewMode
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField size="small" fullWidth
                                                    label="Phone 2"
                                                    value={phone2}
                                                    onChange={(event) => setPhone2(event.target.value)}
                                                    InputProps={{
                                                        readOnly: formOpenMode === FormMode.ViewMode
                                                    }}
                                                />
                                            </Grid>
                                            {
                                                formOpenMode !== FormMode.NewMode &&
                                                <Grid item xs={8}>
                                                    <Stack direction="row" spacing={2}>
                                                        <FormGroup>
                                                            <FormControlLabel sx={{ width: 110 }} disabled={formOpenMode === FormMode.ViewMode}
                                                                control={<Checkbox checked={isOnLeave}
                                                                    onChange={(event) => {
                                                                        setIsOnLeave(event.target.checked);
                                                                    }} />}
                                                                size="small" label="On Leave" />
                                                        </FormGroup>
                                                        <DatePicker
                                                            label="Rejoin Date" readOnly={!isOnLeave}
                                                            slotProps={{
                                                                textField: {
                                                                    size: "small", fullWidth: true, required: { isOnLeave },
                                                                    error: isShowValidationMsgs && isOnLeave && !rejoinDate ? true : false,
                                                                    helperText: isShowValidationMsgs && isOnLeave && !rejoinDate ? 'Enter next rejoin date' : ''
                                                                },
                                                                field: {
                                                                    clearable: true,
                                                                    onClear: () => setCleared(true)
                                                                }
                                                            }}
                                                            value={rejoinDate} minDate={dayjs().add(-10, 'day')}
                                                            onChange={(newValue) => setRejoinDate(newValue)}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            }
                                            {
                                                formOpenMode !== FormMode.NewMode &&
                                                <Grid item xs={4}>
                                                    <TextField size="small" fullWidth
                                                        label="Advance Amount"
                                                        type="number"
                                                        InputProps={{
                                                            readOnly: true
                                                        }}
                                                        value={advanceAmount}
                                                    />
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {
                                            isOpenEditProfilePhoto &&
                                            <DocumentForm isOpen={isOpenEditProfilePhoto} isProfilePhoto={true} loggedUser={homeState.loggedUser}
                                                handleSaveClick={handleProfilePhotoSaveClick}
                                                handleCancelClick={handleProfilePhotoCancelClick}
                                                showAPIError={ShowAPIError} openErrorAlert={OpenErrorAlert} />
                                        }
                                        {
                                            isOpenProfilePhotoDeleteConfirm &&
                                            <ConfirmDialog isOpen={isOpenProfilePhotoDeleteConfirm} title="Confirm Delete"
                                                message="Are you sure you want to delete the profile photo?"
                                                handleClickOK={handleProfilePhotoDeleteConfirmOKClick}
                                                handleClickCancel={handleProfilePhotoDeleteConfirmCancelClick} />

                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{ float: "right" }}>
                                            <ProfilePhotoCard formMode={formOpenMode}
                                                profilePhotoFile={profilePhotoFile} defaultIcon={homeState.accountPersonIcon}
                                                handleEditClick={handleOpenProfilePhotoEditClick}
                                                handleDeleteClick={handleOpenProfilePhotoDeleteClick} />
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Box sx={{ padding: "16px 0" }}>

                                        <NursePersonalnfo expanded={expanded} isShowValidationMsgs={isShowValidationMsgs}
                                            religions={religions} formOpenMode={formOpenMode} selectedNurse={selectedNurse}
                                            handleAccordionChange={handleAccordionChange}
                                            handlePersonalInfoFormValueChange={handlePersonalInfoFormValueChange} />

                                        <NurseFamilyView nurseFamilies={nurseFamilies} expanded={expanded} formOpenMode={formOpenMode}
                                            handleAccordionChange={handleAccordionChange}
                                            handleNurseFamiliesChange={handleNurseFamiliesChange} />

                                        <NurseContact expanded={expanded} contacts={contacts} formOpenMode={formOpenMode}
                                            handleAccordionChange={handleAccordionChange}
                                            handleChange={handleContactChange} />

                                        <NurseDocumentView expanded={expanded} nurseDocuments={documents} formOpenMode={formOpenMode}
                                            loggedUser={homeState.loggedUser} showAPIError={ShowAPIError} openErrorAlert={OpenErrorAlert}
                                            handleAccordionChange={handleAccordionChange} handleDocumentChange={handleDocumentChange}
                                            handleRemovedNurseDocumentIdsChange={handleRemovedNurseDocumentIdsChange}
                                        />

                                    </Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </Stack>
            </LocalizationProvider>
        </Box>
    );
}

export default NurseForm;
