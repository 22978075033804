import React from 'react';
import { Box, Stack, Paper } from '@mui/material';
import { FormMode } from '../utils/extensions/constantEx';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AccordionSummary, AccordionDetails, Accordion } from '../shared/components/accordion';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-in';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { TabPanel, a11yProps } from '../shared/components/tabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Table, TableBody, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import StyledTableRow from '../shared/components/styledTableRow';
import StyledTableCell from '../shared/components/styledTableCell';
import DocumentForm from '../shared/components/documentForm';

const PatientDocumentView = (props) => {
    const { expanded, patientDocuments, handleAccordionChange } = props;

    const [isOpenDocumentForm, setIsOpenDocumentForm] = React.useState(false);

    const handleAddClick = () => {
        setIsOpenDocumentForm(true);
    }

    const handleSaveClick = () => {
        setIsOpenDocumentForm(false);
    }

    const handleCancelClick = () => {
        setIsOpenDocumentForm(false);
    }

    return (

        <Accordion expanded={expanded === 'panelPatientDocument'} onChange={handleAccordionChange('panelPatientDocument')}>
            <AccordionSummary id="panel-patient-documents">
                <Typography>Patient Documents</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box sx={{ pr: 1, float: 'right' }}>
                                <Stack direction="row" spacing={2}>
                                    {
                                        isOpenDocumentForm &&
                                        <DocumentForm isOpen={isOpenDocumentForm} isProfilePhoto={false}
                                            handleSaveClick={handleSaveClick}
                                            handleCancelClick={handleCancelClick} />
                                    }
                                    <Button variant="contained" onClick={handleAddClick}>Add</Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ maxHeight: '77vh' }}>
                                <Table stickyHeader sx={{ minWidth: 700 }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Document</StyledTableCell>
                                            <StyledTableCell>File Name</StyledTableCell>
                                            <StyledTableCell>&nbsp;</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patientDocuments.map((row) => (
                                            <StyledTableRow key={row.name}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.documentName}
                                                </StyledTableCell>
                                                <StyledTableCell>{row.fileName}</StyledTableCell>
                                                <StyledTableCell>&nbsp;</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Stack>
            </AccordionDetails>
        </Accordion>

    );
}
export default PatientDocumentView;