import React, { useState, useEffect } from 'react';
import { Box, Stack, Grid, TextField, Typography, Tabs, Tab } from '@mui/material';
import { AccordionSummary, AccordionDetails, Accordion } from '../shared/components/accordion';
import { TabPanel, a11yProps } from '../shared/components/tabPanel';
import { FormMode } from '../utils/extensions/constantEx';

const NurseContact = (props) => {
    const { formOpenMode, expanded, contacts, handleChange, handleAccordionChange } = props;

    const [selectedContactTabIndex, setSelectedContactTabIndex] = useState(0);
    const [firstContactName, setFirstContactName] = useState('');
    const [firstContactMobileNo, setFirstContactMobileNo] = useState('');
    const [firstContactPhoneNo, setFirstContactPhoneNo] = useState('');
    const [firstContactAddress, setFirstContactAddress] = useState('');
    const [secondContactName, setSecondContactName] = useState('');
    const [secondContactMobileNo, setSecondContactMobileNo] = useState('');
    const [secondContactPhoneNo, setSecondContactPhoneNo] = useState('');
    const [secondContactAddress, setSecondContactAddress] = useState('');
    const [firstContactId, setFirstContactId] = useState(-1);
    const [secondContactId, setSecondContactId] = useState(-2);

    useEffect(() => {
        if (formOpenMode !== FormMode.EditMode) {
            setFirstContactId(-1);
            setFirstContactName('');
            setFirstContactMobileNo('');
            setFirstContactPhoneNo('');
            setFirstContactAddress('');

            setSecondContactId(-1);
            setSecondContactName('');
            setSecondContactMobileNo('');
            setSecondContactPhoneNo('');
            setSecondContactAddress('');
        }
    }, [formOpenMode]);

    useEffect(() => {
        if (contacts && contacts.length > 0 && contacts[0]) {
            setFirstContactId(contacts[0].id);
            setFirstContactName(contacts[0].name ?? '');
            setFirstContactMobileNo(contacts[0].phoneNo1 ?? '');
            setFirstContactPhoneNo(contacts[0].phoneNo2 ?? '');
            setFirstContactAddress(contacts[0].address ?? '');
        }
        if (contacts && contacts.length > 1 && contacts[1]) {
            setSecondContactId(contacts[1].id);
            setSecondContactName(contacts[1].name ?? '');
            setSecondContactMobileNo(contacts[1].phoneNo1 ?? '');
            setSecondContactPhoneNo(contacts[1].phoneNo2 ?? '');
            setSecondContactAddress(contacts[1].address ?? '');
        }
    }, [contacts]);

    useEffect(() => {
        handleContactChange();
    }, [firstContactName, firstContactMobileNo, firstContactPhoneNo, firstContactAddress,
        secondContactName, secondContactMobileNo, secondContactPhoneNo, secondContactAddress
    ]);

    const handleContactTabChange = (event, newValue) => {
        setSelectedContactTabIndex(newValue);
    };

    const handleContactChange = () => {
        const tempContacts = [];
        let tempContact = null;

        if (firstContactName.trim().length > 0 || firstContactMobileNo.trim().length > 0) {
            tempContact = {
                id: firstContactId,
                name: firstContactName,
                phoneNo1: firstContactMobileNo,
                phoneNo2: firstContactPhoneNo,
                address: firstContactAddress
            };
            tempContacts.push(tempContact);
        }
        if (secondContactName.trim().length > 0 || secondContactMobileNo.trim().length > 0) {
            tempContact = {
                id: secondContactId,
                name: secondContactName,
                phoneNo1: secondContactMobileNo,
                phoneNo2: secondContactPhoneNo,
                address: secondContactAddress
            };
            tempContacts.push(tempContact);
        }
        handleChange(tempContacts);
    }

    return (

        <Accordion expanded={expanded === 'panelContacts'} onChange={handleAccordionChange('panelContacts')}>
            <AccordionSummary id="panel-contacts">
                <Typography>Contacts</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedContactTabIndex} onChange={handleContactTabChange}>
                            <Tab label="First Contact" {...a11yProps(0)} />
                            <Tab label="Second Contact" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedContactTabIndex} index={0}>
                        <Stack spacing={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField size="small" fullWidth
                                        label="Name"
                                        value={firstContactName}
                                        onChange={(event) => {
                                            setFirstContactName(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField size="small" fullWidth
                                        label="Mobile No"
                                        value={firstContactMobileNo}
                                        onChange={(event) => {
                                            setFirstContactMobileNo(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField size="small" fullWidth
                                        label="Phone No"
                                        value={firstContactPhoneNo}
                                        onChange={(event) => {
                                            setFirstContactPhoneNo(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField size="small" fullWidth multiline
                                        label="Address"
                                        rows={4}
                                        value={firstContactAddress}
                                        onChange={(event) => {
                                            setFirstContactAddress(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={selectedContactTabIndex} index={1}>
                        <Stack spacing={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField size="small" fullWidth
                                        label="Name"
                                        value={secondContactName}
                                        onChange={(event) => {
                                            setSecondContactName(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField size="small" fullWidth
                                        label="Mobile No"
                                        value={secondContactMobileNo}
                                        onChange={(event) => {
                                            setSecondContactMobileNo(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField size="small" fullWidth
                                        label="Phone No"
                                        value={secondContactPhoneNo}
                                        onChange={(event) => {
                                            setSecondContactPhoneNo(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField size="small" fullWidth multiline
                                        label="Address"
                                        rows={4}
                                        value={secondContactAddress}
                                        onChange={(event) => {
                                            setSecondContactAddress(event.target.value);
                                        }}
                                        InputProps={{
                                            readOnly: formOpenMode === FormMode.ViewMode
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </TabPanel>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default NurseContact;
