export const FormMode = {
    ViewMode: 0,
    NewMode: 1,
    EditMode: 2
};

export const GenderList = [
    {
        "id": 0,
        "label": "Male"
    },
    {
        "id": 1,
        "label": "Female"
    }
]

export const CommonUrls = {
    Company: 'company/<code>/',
    GetReligions: 'religions/',
    ServiceTypes: 'service-types/',
    Document: 'document',
    NurseSeach: 'nurses?search=<search-text>',
    Nurse: 'nurse'
}

export const ConstantValues = {
    DefaultDateValue: new Date(2000, 0, 1)
}