import { Box, Stack, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import NursePaymentFormHeader from './nursePaymentFormHeader';
import { FormMode } from '../utils/extensions/constantEx';

export default function NurseForm(props) {
    const { formMode } = props;

    return (
        <Box sx={{ bgcolor: '#cfe8fc', padding: '8px, 0' }}>
            <Stack spacing={0}>
                <NursePaymentFormHeader formMode={formMode} />
                <Box sx={{ pr: 1, paddingTop: '14px' }}>

                </Box>
            </Stack>
        </Box>
    );
}