import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Stack, Paper, IconButton } from '@mui/material';
import { FormMode } from '../../utils/extensions/constantEx'


const ProfilePhotoCard = (props) => {
    const { formMode, profilePhotoFile, handleEditClick, handleDeleteClick, defaultIcon } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 0,
                    width: 150,
                    height: 150,
                },
            }}
        >
            <Paper>
                <Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                width: 118,
                                height: 114,
                                marginLeft: '15px', marginTop: '8px'
                            },
                        }}
                    >
                        {
                            (!profilePhotoFile || !profilePhotoFile.uploadedFile) && defaultIcon &&
                            <img src={defaultIcon} alt="" />
                        }
                        {
                            profilePhotoFile && profilePhotoFile.uploadedFile &&
                            <img src={profilePhotoFile.uploadedFile} alt="" />
                        }
                    </Box>

                    <Stack direction="row">
                        {
                            formMode !== FormMode.ViewMode &&
                            <Box sx={{ width: '50%' }}>
                                <IconButton size="small" onClick={handleEditClick}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        }
                        {
                            formMode !== FormMode.ViewMode && profilePhotoFile && profilePhotoFile.uploadedFile &&
                            <Box sx={{ width: '50%', textAlign: 'right' }}>
                                <IconButton size="small" onClick={handleDeleteClick}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        }
                    </Stack>
                </Stack>
            </Paper>
        </Box>

    );
}

export default ProfilePhotoCard;