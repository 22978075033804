import { Box, Paper, Grid, Typography, Button, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function NursePaymentSearchHeader() {
    return (

        <Paper>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item xs={2}>
                    <Typography variant="h5" sx={{ p: 2 }}>
                        Nurse Salary Payments
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Paper elevation={1} variant="outlined"
                        sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Nurse"
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{ pr: 1, textAlign: 'right', paddingTop: '14px' }}>
                        <Button variant="outlined">Add Salary Payment</Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>

    );
}