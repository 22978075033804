import React, { useState, useEffect, useContext } from 'react';
import { Box, Stack, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import StyledTableRow from '../shared/components/styledTableRow';
import StyledTableCell from '../shared/components/styledTableCell';
import NurseMenuPopup from './nurseMenuPopup';
import NurseHeader from './nurseHeader';
import { HomeContext } from '../utils/contexts/homeContext';
import { GetNurseSeachAPIUrl } from '../utils/extensions/functionEx';
import PageLoading from '../shared/components/backdrop';

const NurseSearch = (props) => {

    const { ShowAPIError, OpenErrorAlert, SetNurseSearchText, homeState } = useContext(HomeContext);

    const [dbNurses, setBbNurses] = useState([]);
    const [isOpenPageLoad, setIsOpenPageLoad] = useState(false);

    useEffect(() => {
        async function fetchNurses() {
            searchNurses(homeState.nurseSearchText.length > 0 ? homeState.nurseSearchText : '');
        }
        fetchNurses();
    }, []);

    const searchNurses = async (searchText) => {
        try {
            setIsOpenPageLoad(true);
            let tempNurses = [];
            SetNurseSearchText(searchText);
            const response = await fetch(GetNurseSeachAPIUrl(homeState.loggedUser.companyCode, searchText))
            if (response.status === 200) {
                tempNurses = await response.json();
            } else {
                ShowAPIError(response)
            }
            setBbNurses([...tempNurses]);
        }
        catch (error) {
            OpenErrorAlert(error)
        } finally {
            setIsOpenPageLoad(false);
        }
    }

    const handleNurseSearchClick = (searchText) => {
        searchNurses(searchText.trim());
    }

    const handlePageLoadingClose = () => {
        setIsOpenPageLoad(false);
    }

    return (
        <Box sx={{ bgcolor: '#cfe8fc', padding: '8px, 0' }}>
            <Stack spacing={0}>
                {
                    isOpenPageLoad &&
                    <PageLoading isOpenPageLoading={isOpenPageLoad} handleClose={handlePageLoadingClose} />
                }
                <NurseHeader handleNurseSearchClick={handleNurseSearchClick} />
                <TableContainer component={Paper} sx={{ maxHeight: '79vh', borderRadius: '0' }}>
                    <Table stickyHeader sx={{ minWidth: 700 }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" sx={{ width: '55px' }}></StyledTableCell>
                                <StyledTableCell sx={{ width: '250px' }}>Code</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Phone Nos</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dbNurses.map((row) => (
                                <StyledTableRow key={row.id.toString()}>
                                    <StyledTableCell align="center">
                                        <NurseMenuPopup nurseRow={row} />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.code}
                                    </StyledTableCell>
                                    <StyledTableCell>{row.nurse_name}</StyledTableCell>
                                    <StyledTableCell>{row.phone}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Box>
    );
}
export default NurseSearch;